.layout {
  background-color: #333;
}

.layout .react-grid-item {
  background-color: #444;
  border: 1px solid #555;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.15);
}

.layout .react-grid-item.resizing {
  border-color: orange;
}

.layout .react-grid-item.dragging {
  z-index: 100;
    x-shadow: 0 2px 6px rgba(255, 255, 255, 0.15), 0 10px 20px rgba(255, 255, 255, 0.1);
}

.layout .react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.layout .react-grid-item > .react-resizable-handle::before {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}
